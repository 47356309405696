.white-background {
  background: #F7F7F7;
}

.cream {
  background-color: rgba(244, 232, 232, 0.755);
}

.brown {
  color: rgb(129, 51, 51);
}

.selector button:hover {
  background-color: #c4a6a6; 
  color: #000;
  cursor: pointer;
}

.selector button {
  background-color: rgba(225, 198, 198, 0.755); 
}

.selector .active {
  background-color: #c4a6a6 !important;
}

@keyframes fadeIn-1 {
  0% {
    opacity: 0;
    transform: translateY(+100px);
  }
  60% {
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn-3 {
  0% {
    opacity: 0;
    transform: translateX(+100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes roll-right {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes roll-left {
  0% {
    -webkit-transform: rotate(-0deg);
    transform: rotate(-0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); 
  }
}

@keyframes pop-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.fade-1 {
  animation: fadeIn-1 0.6s; 
}

.fade-2 {
  opacity: 0;
  animation: fadeIn-2 1.25s forwards; 
  animation-delay: 0.6s;
}

.roll-right {
  animation: roll-right 1.25s forwards;
  animation-delay: 0.6s;
}

.roll-left {
  animation: roll-left 1.25s forwards;
  animation-delay: 1.85s; 
}

.fade-3 {
  opacity: 0;
  animation: fadeIn-3 1.25s forwards; 
  animation-delay: 1.85s;
}

.row-transition {
  animation: pop-in 0.3s forwards; 
  animation-delay: 3.5s;
}

.inner-border {
    /*background-color: #f4e7da; */
    border-radius: 50px;
}


.double-border-text {
    text-transform: uppercase;
    font-size: extra-large;
    color: rgb(129, 51, 51);
    -webkit-text-stroke-width: stroke-width;
    -webkit-text-stroke-color: lighten(adjust-hue(yellow, -5%), 35%);
  }

  .leaderboard-description {
    text-transform: uppercase;
    color: rgb(129, 51, 51);
    -webkit-text-stroke-width: stroke-width;
    -webkit-text-stroke-color: lighten(adjust-hue(yellow, -5%), 35%);
    font-weight: bold;
  }

  .selected-user-frame {
    text-transform: uppercase;
    color: rgb(170, 237, 153);
    -webkit-text-stroke-width: stroke-width;
    -webkit-text-stroke-color: lighten(adjust-hue(yellow, -5%), 35%);
    font-weight: bold;
  }

  .leaderboard-frame {
    display: flex; 
    flex-direction: column; 
    gap: 10px; 
    align-items:stretch;
    padding-left: 15px; 
    padding-right: 15px;
    padding-bottom: 15px; 
  }

  .user-frame {
    border: 2px solid white; 
    border-radius: 40px; 
    padding: 10px; 
    display: flex; 
    flex-direction: row; 
    gap: 10px; 
    background-color: white;
  }

  .user-frame:hover {
    background-color: #f2ede7;
    cursor: pointer;
  }

  .rank-icon {
    width: 50px; 
    height: 50px; 
  }

  .rank-number {
    text-transform: uppercase;
    color: rgb(129, 51, 51);
    -webkit-text-stroke-width: stroke-width;
    -webkit-text-stroke-color: lighten(adjust-hue(yellow, -5%), 35%);
    font-weight: bold;
    font-size:larger;
  }


  @media (max-width: 1024px){
    .user-frame {
      padding: 5px; 
      gap: 5px; 
      font-size:medium;
    }

    .user-frame h2 {
      font-size:small;
    }

    .rank-number {
      font-size: medium !important; 
    }

    .rank-icon {
      width: 30px; 
      height: 30px; 
    }

    .profile-icon {
      width: 30px;
      height: 30px;
    }
  }
