
.journal-section {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 25px;
  position: relative;
  margin: 25px 0;
}


.journal-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.journal-section-title {
  color: #2c3e50;
  text-align: center;
  margin: 0;
  padding: 10px 20px;
  font-size: 24px;
  font-weight: 600;
}

.see-all-button, .create-journal-btn {
  top: 20px;
  right: 25px;
  font-size: 1rem;
  padding: 8px 16px;
  background: linear-gradient(145deg, rgba(255, 174, 163, 0.8), rgba(255, 147, 119, 0.8));
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  opacity: 0.95;
}

.see-all-button:hover, .create-journal-btn:hover {
  background: linear-gradient(145deg, rgba(255, 174, 163, 0.9), rgba(255, 147, 119, 0.9));
  box-shadow: 0 6px 8px rgba(0,0,0,0.15);
  transform: translateY(-2px);
  opacity: 1;
}

.create-journal-btn {
  padding: 10px 20px;
  font-weight: bold;
}

.journal-cover {
  position: relative;
  width: 100%;
  padding-top: 141.42%;
  background-size: cover;
  background-position: center;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.journal-cover-title {
  position: absolute;
  bottom: 10%;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: initial;
  color: #333;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 5%;
  background-color: rgba(255, 255, 255, 0.5);
  margin-bottom: 75%;
}

.journal-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  margin: 0 auto;
}

.journal-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #333;
  overflow: hidden;
  transition: transform 0.2s;
  background-color: white;
}


.journal-card:hover {
  transform: translateY(-2px);
}
.no-journals-text {
  text-align: center;
  color: #6c757d;
  padding: 20px;
  font-size: 18px
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #bca89f;
  color: white;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: background-color 0.3s ease;
}

.scroll-button:hover {
  background-color: #a08f87;
}

.scroll-button.disabled {
  opacity: 0.5;
  cursor: default;
}

.prev {
  left: 5px;
}

.next {
  right: 5px;
}




@media (max-width: 500px) {
  .journal-section-title, .no-journals-text {
    font-size: 20px;
    padding-bottom: 20px;
  }

  .journal-grid {
    gap: 10px;
  }

  .journal-card {
    flex: 0 0 100%;
  }

  .scroll-button.prev, .scroll-button.next {
    width: 24px;
    height: 24px;
  }
}
