.popupIcon:hover {
    transform: scale(1.2);
}
.popupIcon:focus {
    transform: scale(1.2);
}
.popupIcon:active {
    transform: scale(1.1);
}
.popupIcon {
    transition : transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.5);
    height: 35px;
 
}

