
.home-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  margin: 0;
  padding: 0;
  padding: 10px;
}

.main-content {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

  .home-layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .main-content {
    flex: 2;
    height: 100vh;
    overflow-y: auto;
    padding-right: 10px;
  }


.statistics-section {
  flex: 0.5;
  display: flex;
  flex-direction: column;
}

html {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.landing-home-body {
  margin: 0; 
  padding: 0;
  height: 100vh;
  overflow: hidden;
}




@media (min-width: 768px) and (max-width: 1050px) {
  .main-content {
    /* Adjust as needed to leave some space for the statistics section */
    width: calc(100% - 300px);
  }
  .statistics-section {
    /* Make sure it doesn't take the full width and is perhaps hidden or adjusted */
    width: 250px;
    float: right; /* Example to keep it on the side */

  }

}

/* Ensure full width for small devices and adjustments for the statistics section */
@media (max-width: 767px) {
  .main-content,
  .statistics-section {
    width: 100%;
  }
  .statistics-section {
    margin-top: 25px;
  }



}


.main-content::-webkit-scrollbar {
  display: none;
}

.main-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

