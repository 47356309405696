

.welcome-section {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.welcome-section h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 10px;
}

.welcome-section p {
  color: #666;
}

.cta-button {
  padding: 12px 24px;
  background: linear-gradient(145deg, rgba(255, 174, 163, 0.8), rgba(255, 147, 119, 0.8));
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  transition: background-color 0.3s, box-shadow 0.2s;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  opacity: 0.95;
}

.cta-button:hover {
  background: linear-gradient(145deg, rgba(255, 174, 163, 0.9), rgba(255, 147, 119, 0.9));
  box-shadow: 0 6px 8px rgba(0,0,0,0.15);
  transform: scale(1.05);
  opacity: 1;
}