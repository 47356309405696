/* Profile.css */

.profile-content h2,
.profile-content h3 {
    color: #333;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.profile-content p {
    color: #666;
    line-height: 1.8;
    margin: 10px 0;
    font-size: 0.8rem;
}

.profile-detail {
    background-color: #f9f9f9;
    padding: 5px 10px;
    margin: 10px 0;
    border-left: 4px solid #007bff;
    border-radius: 5px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
}

.flame-icon {
    font-size: 1rem;
    margin-right: 5px;
}

.profile-detail strong {
    color: #333;
    margin-right: 5px;
}

.profile-highlight {
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    margin: 20px 0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-highlight strong {
    color: #fff;
}

.item-list {
    list-style-type: none;
    padding: 0;
}

.item-list li {
    display: inline-block;
    margin-right: 10px;
}

.item-list li img {
    width: 60px;
    height: auto;
    border-radius: 5px;
    transition: transform 0.2s;
}

.item-list li img:hover {
    transform: scale(1.1);
}

.achievements {
    margin-top: 20px;
}

.unearned {
    filter: grayscale(100%);
}

.fav-badge-icon {
    width: 30px;
    vertical-align: middle;
    margin-right: 10px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
}

.modal-badge {
    display: block;
    width: 12rem;
    height: auto;
    margin: 5px 0px;
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-top: 20px;
}

.button-cancel,
.button-set-favourite {
    flex: 1;
    margin: 0 30px;
}

.button-cancel {
    background-color: #d3d3d3;
    color: #333;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.button-set-favourite {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.button-unselect-favourite {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.button-locked {
    background-color: #888;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: not-allowed;
}